<template>
    <section class="mb-10">   
        <v-layout justify-space-around justify-center class="contact-layout">
            <v-flex xs9 sm9 offset-sm0 class="" align="center" justify="center" >
                    <v-card flat tile class="mt-4" color=""  >
                             <v-row  class="ma-0">
                                 <v-col cols="12" lg="8" md="8" sm="12" xs="12" class="pa-1" >
                                     <Map />
                                 </v-col>
                                 <v-col cols="12" lg="4" md="4" sm="12" xs="12" class="">
                                     <Address />
                                 </v-col>
                             </v-row>
                              <v-row  class="ma-0">
                                     <ContactForm />

                              </v-row>
                    </v-card>
            </v-flex>
        </v-layout>
    </section>
</template>

<script>
export default {
    name: 'ContactHolder',

     components: {
      Map: () => import('./map'),
      ContactForm: () => import('./contactForm'),
      Address: () => import('./address'),

      

      
     },

}
</script>

<style>

</style>